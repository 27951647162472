@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: #fcfcfc;
  font-family: "GraphikRegular";
  /* font-family: 'GraphikRegular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.citrone-red {
  background: #f64f59;
}

:hover.citrone-red {
  background: #fb3440;
}

@layer base {
  @font-face {
    font-family: "GraphikBlack";
    font-weight: 900;
    src: local("GraphikBlack"), url(./assets/fonts/GraphikBlack.otf) format("opentype");
  }
  @font-face {
    font-family: "GraphikBold";
    font-weight: 700;
    src: local("GraphikBold"), url(./assets/fonts/GraphikBold.otf) format("opentype");
  }
  @font-face {
    font-family: "GraphikBoldItalic";
    font-weight: 700;
    font-style: italic;
    src: local("GraphikBoldItalic"), url(./assets/fonts/GraphikBoldItalic.otf) format("opentype");
  }
  @font-face {
    font-family: "GraphikExtralightItalic";
    font-weight: 200;
    font-style: italic;
    src: local("GraphikExtralightItalic"), url(./assets/fonts/GraphikExtralightItalic.otf) format("opentype");
  }
  @font-face {
    font-family: "GraphikLight";
    font-weight: 300;
    src: local("GraphikLight"), url(./assets/fonts/GraphikLight.otf) format("opentype");
  }
  @font-face {
    font-family: "GraphikLightItalic";
    font-weight: 300;
    font-style: italic;
    src: local("GraphikLightItalic"), url(./assets/fonts/GraphikLightItalic.otf) format("opentype");
  }
  @font-face {
    font-family: "GraphikMedium";
    font-weight: 500;
    src: local("GraphikMedium"), url(./assets/fonts/GraphikMedium.otf) format("opentype");
  }
  @font-face {
    font-family: "GraphikMediumItalic";
    font-weight: 500;
    font-style: italic;
    src: local("GraphikMediumItalic"), url(./assets/fonts/GraphikMediumItalic.otf) format("opentype");
  }
  @font-face {
    font-family: "GraphikRegular";
    font-weight: 400;
    src: local("GraphikRegular"), url(./assets/fonts/GraphikRegular.otf) format("opentype");
  }
  @font-face {
    font-family: "GraphikSemibold";
    font-weight: 600;
    src: local("GraphikSemibold"), url(./assets/fonts/GraphikSemibold.otf) format("opentype");
  }
}

/* 
Font Weight	Numerical Weight
"Thin", "Hairline"	100
"Extra Light", "Ultra Light"	200
"Light", "Book"	300
"Normal", "Regular", "Roman", "Standard", "Plain"	400/Normal
"Medium", "Demi"	500
"Semi Bold", "Semi Bld", "Demi Bold", "Demi Bld"	600
"Bold", "Bld"	700/Bold
"Extra Bold", "Ultra Bold", "Extra Bld", "Ultra Bld"	800
"Black", "Heavy", "Ultra", "Fat", "Poster", "Ultra Black" 	 900 
*/

.installHidden {
  display: none !important;
}
#installContainer {
  position: absolute;
  top: 1rem;
  right: 0px;
  z-index: 1900;
  height: 100%;
  width: 100%;
  clear: both;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.88);
  animation-duration: 0.35s;
  animation-fill-mode: both;
  animation-name: fadeIn;
}
#installContainer button {
  background-color: #f64f59;
  border: 1px solid #f64f59;
  color: #ffffff;
  border-radius: 10px;
  font-size: 1em;
  padding: 0.75em;
  margin-right: 1rem;
  /* font-weight: bold; */
}
#installModalBg {
  width: 20rem;
  height: 11rem;
  background-color: #f3f4f6;
  border: 2px solid #8f70ff;
  border-radius: 25px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#installModal {
  /* font-weight: bold; */
  padding: 1rem;
}
#installModalBtn {
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.iosHidden {
  display: none !important;
}

#iosInstallContainer {
  position: absolute;
  top: 1rem;
  right: 0px;
  z-index: 1900;
  height: 100%;
  width: 100%;
  clear: both;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.88);
  animation-duration: 0.35s;
  animation-fill-mode: both;
  animation-name: fadeIn;
}
#iosInstallContainer button {
  background-color: #f64f59;
  border: 1px solid #f64f59;
  color: #ffffff;
  border-radius: 10px;
  font-size: 1em;
  padding: 0.75em;
  margin-left: 1rem;
  /* font-weight: bold; */
}
#iosInstallModalBg {
  width: 20rem;
  height: 12rem;
  background-color: #f3f4f6;
  border: 2px solid #8f70ff;
  border-radius: 25px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#iosInstallModal {
  /* font-weight: bold; */
  padding: 1rem;
}
#iosInstallModalBtn {
  padding-left: 1rem;
  padding-bottom: 1rem;
}

::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #a8bbbf;
}
::-webkit-scrollbar-thumb {
  background-color: #a8bbbf;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: #a8bbbf;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background-color: #838a8b;
}

/* Style the list */
ul.breadcrumb {
  padding: 15px 15px;
  list-style: none;
  /* background-color: #eee; */
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 14px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li + li:before {
  padding: 4px;
  content: ">";
  font-weight: bold;
}

/* Add a color to all links inside the list */
/* ul.breadcrumb li button {
  color: #f64f59;
  text-decoration: none;
} */

/* Add a color on mouse-over */
ul.breadcrumb li button:hover {
  color: #f64f59;
  text-decoration: underline;
}

.citrone-background-color-css {
  background-color: #fcfcfc;
}

.citrone-dialogue-background-css {
  width: 700px;
  /* height: 600px; */
  top: 160px;
  background: #ffffff;
  border: 1px solid #e7edf3;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(30, 34, 37, 0.02);
  border-radius: 4px;
}
@media only screen and (max-width: 765px) {
  .citrone-dialogue-background-css {
    width: 350px;
    /* height: 37.5rem; */
    top: 160px;
    background: #ffffff;
    border: 1px solid #e7edf3;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px rgba(30, 34, 37, 0.02);
    border-radius: 4px;
  }
}

.citrone-members-details-bg {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(246, 79, 89, 0.1);
  border-radius: 5px;
}
@media only screen and (max-width: 765px) {
  .citrone-members-details-bg {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(246, 79, 89, 0.1);
    border-radius: 5px;
  }
}

.linkedin-color {
  background: #0077b7;
}
.linkedin-color:hover {
  background: #046599;
}

.btn-loading {
  animation-name: btn-loading;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes btn-loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input:checked ~ .lock-toggle {
  transform: translateY(100%);
  background-color: white;
}

/* input:checked ~ .lesson-sequential-toggle { */
/* transform: translateX(100%); */
/* background-color: white; */
/* } */

.signup-btn {
  z-index: 10;
  border-radius: 15px;
  border: 0px;
  font-weight: 600;
  color: white;
  font-family: "Source Sans Pro", sans-serif;
  cursor: pointer;
  font-size: 1.1rem;
  padding: 3%;
  transition: padding 1s, font-size 1s;
  background-image: linear-gradient(322.24deg, #fabd8b -4.38%, #f64f59 48.84%);
  box-shadow: 0px 0px 20px rgb(255 71 71 / 50%), 0px 5px 5px -1px rgb(233 58 58 / 25%),
    inset 4px 4px 8px rgb(255 175 175 / 52%), inset -4px -4px 8px rgb(216 19 19 / 36%);
}

.signup-btn:hover {
  font-size: 1.2rem;
  padding: 4%;
  background-image: linear-gradient(126.58deg, #fad18b -3.24%, #f64f59 57.16%);
  box-shadow: 0px 5px 50px rgb(255 71 71 / 75%), 0px 10px 10px -4px rgb(233 58 58 / 36%),
    inset 4px 4px 8px rgb(255 175 175 / 52%), inset -4px -4px 8px rgb(216 19 19 / 36%);
}

.box-shadow-css {
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.citrone-bg {
  z-index: -10;
}

.m-view-css {
  height: 80vh;
}

.vh60-view-css {
  height: 60vh;
}

.bg-color-css {
  background-color: #fcfcfc;
}

.citrone-transparent-btn {
  border-radius: 4px;
  color: #f64f59;
  border: 1px solid #f64f59;
}

:hover.citrone-transparent-btn {
  color: #ffffff;
  background: #f64f59;
  border: 1px solid #f64f59;
}

.sign-up-button-cta {
  z-index: 200;
  color: white;
  width: 330px;
  height: 55px;
  left: 102px;
  top: 461px;
  border-radius: 2px;
}

.sign-up-button-dots {
  z-index: -200;
  width: 144px;
  height: 85.06px;
  margin-left: 250px;
  margin-top: -68px;
}

.sign-up-button-cta-mobile {
  z-index: 200;
  color: white;
  width: 250px;
  height: 55px;
  left: 102px;
  top: 461px;
  border-radius: 2px;
}

.sign-up-button-dots-mobile {
  z-index: -200;
  width: 144px;
  height: 85.06px;
  margin-left: 150px;
  margin-top: -68px;
}

.ce-block__content,
.ce-toolbar__content {
  max-width: 1000px !important;
}

.cdx-block {
  max-width: 100% !important;
}

/* .ce-block__content, .ce-toolbar__content { max-width:calc(100% - 80px) !important; } .cdx-block { max-width: 100% !important; } */

.top-scroll-bar {
  transform: rotateX(180deg);
}

.flip-back {
  transform: rotateX(180deg);
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.is3zIndex {
  z-index: 3;
}
